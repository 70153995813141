<ion-item class="ion-text-center" *ngIf="!loading && !node">
  <p>Unable to load roll stock details</p>
</ion-item>
<div *ngIf="node">
  <app-value-print [inline]="true" label="Barcode" [value]="node.field_rs_barcode"></app-value-print>
  <app-value-print [inline]="true" label="ID" [value]="node.nid"></app-value-print>
  <app-value-print [inline]="true" label="Width" [value]="node.field_rs_width" type="number"></app-value-print>
  <app-value-print [inline]="true" label="Caliper" [value]="node.field_rs_caliper" type="number"></app-value-print>
  <app-value-print [inline]="true" label="Diameter" [value]="node.field_rs_diameter" type="number"></app-value-print>
  <app-value-print [inline]="true" label="Current Weight" [value]="node.field_weight" type="number"></app-value-print>
  <!--<app-value-print [inline]="true" label="Core" [value]="node.field_core_size"></app-value-print>-->
  <app-value-print [inline]="true" label="Footage (manual)" [value]="node.field_footage_manual_"></app-value-print>
  <app-value-print [inline]="true" label="Material Type" [value]="node.field_material_type"></app-value-print>
  <app-value-print [inline]="true" label="Material Type Other" [value]="node.field_material_type_other">
  </app-value-print>
  <app-value-print [inline]="true" label="Warehouse Location" [value]="node.field_warehouse_location">
  </app-value-print>
  <app-value-print [inline]="true" label="Bay Location" [value]="node.field_bay_location"></app-value-print>
  <app-value-print [inline]="true" label="Defective Notes" [value]="node.field_rs_notes"></app-value-print>
  <app-value-print [inline]="true" label="Project" [value]="node. field_project"></app-value-print>
  <app-value-print [inline]="true" label="Pending" [value]="node.field_pending" type="boolean"></app-value-print>
  <app-value-print [inline]="true" label="Incoming Weight" [value]="node.field_initial_weight" type="number">
  </app-value-print>
  <app-value-print [inline]="true" label="Incoming Roll #" [value]="node.field_rs_roll_num"></app-value-print>
  <app-value-print [inline]="true" label="Incoming Roll # Other" [value]="node.field_rs_roll_num_other">
  </app-value-print>
  <app-value-print [inline]="true" label="Inventory ID" [value]="node.field_inventory_id"></app-value-print>
  <app-value-print [inline]="true" label="Production Type" [value]="node.field_production_type"></app-value-print>
  <app-value-print [inline]="true" label="Board MFG" [value]="node.field_board_mfg"></app-value-print>
  <app-value-print [inline]="true" label="Comments" [value]="node.field_rs_comments"></app-value-print>
  <app-value-print [inline]="true" label="Customer" [value]="node.field_customer2"></app-value-print>
  <app-value-print [inline]="true" label="PO" [value]="node.field_itempo"></app-value-print>
  <!--<app-value-print [inline]="true" label="Master Roll Stock" [value]="node.field_master_roll" type="barcodes">
  </app-value-print>-->
  <!--<app-value-print [inline]="true" label="Created Roll Stock(s)" [value]="node.field_child_rolls" type="barcodes">
  </app-value-print>-->
</div>

<p class="ion-text-center" *ngIf="loading">
  <ion-spinner></ion-spinner>
</p>