<ion-list class="ion-text-center" *ngIf="!loading && !node">
  <p>Unable to load roll stock details</p>
</ion-list>
<ion-list *ngIf="node">
  <ion-item-divider>
    <ion-label>Roll Stock Details</ion-label>
  </ion-item-divider>
  <app-value-print label="Roll Stock ID" [value]="node.nid"></app-value-print>
  <app-value-print label="Barcode" [value]="node.field_rs_barcode"></app-value-print>
  <app-value-print label="Width" [value]="node.field_rs_width" type="number"></app-value-print>
  <app-value-print label="Caliper" [value]="node.field_rs_caliper" type="number"></app-value-print>
  <app-value-print label="Diameter" [value]="node.field_rs_diameter" type="number"></app-value-print>
  <app-value-print label="Current Weight" [value]="node.field_weight" type="number"></app-value-print>
  <app-value-print label="Core" [value]="node.field_core_size"></app-value-print>
  <app-value-print label="Footage (manual)" [value]="node.field_footage_manual_"></app-value-print>
  <app-value-print label="Material Type" [value]="node.field_material_type"></app-value-print>
  <app-value-print label="Material Type Other" [value]="node.field_material_type_other"></app-value-print>
  <app-value-print label="Warehouse Location" [value]="node.field_warehouse_location"></app-value-print>
  <app-value-print label="Bay Location" [value]="node.field_bay_location"></app-value-print>
  <app-value-print label="Defective Notes" [value]="node.field_rs_notes"></app-value-print>
  <app-value-print label="Project" [value]="node. field_project"></app-value-print>
  <app-value-print label="Pending" [value]="node.field_pending" type="boolean"></app-value-print>
  <app-value-print label="Incoming Weight" [value]="node.field_initial_weight" type="number"></app-value-print>
  <app-value-print label="Incoming Roll #" [value]="node.field_rs_roll_num"></app-value-print>
  <app-value-print label="Incoming Roll # Other" [value]="node.field_rs_roll_num_other"></app-value-print>
  <app-value-print label="Inventory ID" [value]="node.field_inventory_id"></app-value-print>
  <app-value-print label="Production Type" [value]="node.field_production_type"></app-value-print>
  <app-value-print label="Board MFG" [value]="node.field_board_mfg"></app-value-print>
  <app-value-print label="Comments" [value]="node.field_rs_comments"></app-value-print>
  <app-value-print label="Customer" [value]="node.field_customer2"></app-value-print>
  <app-value-print label="PO" [value]="node.field_itempo"></app-value-print>
  <app-value-print label="Master Roll Stock" [value]="node.field_master_roll" type="barcodes"></app-value-print>
  <app-value-print label="Created Roll Stock(s)" [value]="node.field_child_rolls" type="barcodes"></app-value-print>

  <div class="ion-text-center">
    <ion-button expand="block" (click)="createNote()" size="large">Attach Note <ion-icon name="attach" slot="end">
      </ion-icon>
    </ion-button>
    <ion-button expand="block" (click)="createAudit()" size="large">Audit <ion-icon name="clipboard" slot="end">
      </ion-icon>
    </ion-button>
    <ion-button expand="block" (click)="createChildRoll()" size="large" class="ion-text-wrap">Create Roll from this Master</ion-button>
  </div>
  <app-value-print label="Notes" [value]="node.field_media_notes" type="notes"></app-value-print>
  <app-value-print label="Audits" [value]="node.field_audit" type="audits"></app-value-print>

</ion-list>

<p class="ion-text-center" *ngIf="loading">
  <ion-spinner></ion-spinner>
</p>