import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewService } from 'src/app/services/ngx-drupal7-services';
import { HelpersService, CreateDeliveryService } from 'src/app/services/helpers.service';
import { CreateNotePage } from './../../pages/modal/create-note/create-note.page';
import { CreateChildRollPage } from './../../pages/modal/create-child-roll/create-child-roll.page';
import { ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-roll-stock',
  templateUrl: './roll-stock.component.html',
  styleUrls: ['./roll-stock.component.css']
})
export class RollStockComponent implements OnInit {

  loading: Boolean = true;
  @Input() node: any;
  @Output() modified = new EventEmitter<boolean>();

  constructor(
    private viewService: ViewService,
    public helpers: HelpersService,
    private modalCtrl: ModalController,
    public alertController: AlertController,
  ) {

  }


  initialize() {
    this.loading = false;
  }

  async createNote() {
    const modal = await this.modalCtrl.create({
      component: CreateNotePage,
      componentProps: { node: this.node }
    });
    modal.onDidDismiss().then((result: any) => {
      // console.log("Create Note Dismissed", result);
      if (result.data && result.data.note) {
        this.modified.emit(true);
      }
    });
    return await modal.present();
  }
  createAudit() {
    this.helpers.createAudit(this.node).then((result) => {
      console.log('Create Audit REsult', result);
      if (result) {
        this.modified.emit(true);
        this.helpers.goBack();
      }
    }, err => {
      this.helpers.presentToast(err);
    });
  }

  async createChildRoll() {
    const modal = await this.modalCtrl.create({
      component: CreateChildRollPage,
      componentProps: { node: this.node }
    });
    modal.onDidDismiss().then(async (result: any) => {
      console.log('Create Child Dismissed', result);
      if (result.data && result.data.child) {
        let child_nid = result.data.child.nid;
        this.modified.emit(true);
        const alert = await this.alertController.create({
          header: "Child Roll was created",
          message: "How would you like to proceed?",
          buttons: [
            {
              text: 'Master Roll/Create another Child',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            }, {
              text: 'Edit Child Roll Created',
              handler: () => {
                this.helpers.go('/main/dashboard/roll-stock/' + child_nid);
              }
            }, {
              text: 'I\'m finished. Return to Home',
              handler: () => {
                this.helpers.go('/main', false)
              }
            }

          ]
        });
        await alert.present();
      }
    });
    return await modal.present();
  }

  ngOnInit() {
    if (this.node && this.node.nid) {
      // console.log("Node", this.node);
      this.initialize();
    } else {
      this.loading = false;
    }

  }

}
