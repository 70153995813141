<ion-header>
    <ion-toolbar color="medium">
        <ion-buttons slot="start">
            <ion-button
                size="small"
                shape="round"
                (click)="closeModal()"
                color="tertiary"
                color="dark"
            >
                <ion-icon slot="start" name="close"></ion-icon> Close
            </ion-button>
        </ion-buttons>
        <ion-title
            >Create New Roll<ion-icon size="small" slot="icon-only" name="attach"></ion-icon>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content (swipe)="helpers.swipe($event)">
    <div class="ion-padding-horizontal">
        <p>Use this when some of the roll has been used. By submitting this form:</p>
        <ol>
            <li>A new roll will be created with the new weight, barcode and PO used.</li>
            <li>The existing roll will be assigned as the Parent so lineage is maintained.</li>
        </ol>
    </div>
    <form #form="ngForm" (ngSubmit)="submit(form)">
        <ion-item>
            <ion-button
                slot="end"
                size="default"
                shape="round"
                color="dark"
                (click)="!this.submitting && scan()"
            >
                <ion-icon name="barcode" slot="start"></ion-icon>
                <ion-label class="ion-hide-sm-down">Scan</ion-label>
            </ion-button>
            <ion-label position="fixed">Barcode</ion-label>
            <ion-input name="barcode" type="text" placeholder="..." [(ngModel)]="barcode">
            </ion-input>
        </ion-item>
        <ion-item>
            <ion-button
                slot="end"
                size="default"
                shape="round"
                color="dark"
                (click)="!this.submitting && scanPO()"
            >
                <ion-icon name="barcode" slot="start"></ion-icon>
                <ion-label class="ion-hide-sm-down">Scan</ion-label>
            </ion-button>
            <ion-label position="fixed">PO</ion-label>
            <ion-input name="po" type="text" placeholder="..." [(ngModel)]="po"> </ion-input>
        </ion-item>
        <ion-item>
            <ion-label position="fixed">Weight</ion-label>
            <ion-input name="weight" type="text" placeholder="..." [(ngModel)]="weight">
            </ion-input>
        </ion-item>

        <div class="ion-text-center">
            <app-progress-bar *ngIf="submitting" [progress]="progress"></app-progress-bar>
            <ion-button size="large" type="submit" [disabled]="submitting" expand="block">
                Create a Child Roll
            </ion-button>
        </div>
    </form>
</ion-content>
